import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

import { SharedDirectiveModule } from '@gp-angular/shared/directive';
import { ServiceWidgetModule, WidgetService } from '@gp-angular/service/widget';
import { ApplicationDateAdapterService } from '@gp-angular/service/application';
import { ServiceHttpModule } from '@gp-angular/service/http';
import { EnvironmentService } from '@gp-angular/service/environment';
import { HttpLoaderFactory, InitTranslateFactory } from '@gp-angular/service/translate';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { InterfaceComponent } from './interface/interface.component';

import { ApiModule as AppointmentApiModule, BASE_PATH as AppointmentBasePath } from '@noventi/gp-platform/online-appointments';
import { ApiModule as CareProviderApiModule, BASE_PATH as PersonBasePath } from '@noventi/gp-platform/care-providers';

@NgModule({
	declarations: [
		AppComponent,
		InterfaceComponent
	],
	imports: [
		TranslateModule.forRoot({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useFactory: (HttpLoaderFactory),
				deps: [HttpClient]
			},
			isolate: false,
			extend: true
		}),
		StoreModule.forRoot({}, {
			//metaReducers,
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
				strictStateSerializability: true,
				strictActionSerializability: true,
				strictActionWithinNgZone: true,
				strictActionTypeUniqueness: true
			}
		}),
		EffectsModule.forRoot([]),

		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
		}),


		RouterModule.forRoot([{path: '**', component: InterfaceComponent}], {useHash: false, relativeLinkResolution: 'legacy'}),

		BrowserModule,
		BrowserAnimationsModule,

		ServiceWidgetModule,
		SharedDirectiveModule,

		/** Instead of HttpEndpointsModule, import only what is mandatory */
		ServiceHttpModule,

		MatButtonModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		MatDialogModule,
		MatFormFieldModule,
		MatInputModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		ReactiveFormsModule,
	],
	providers: [
		{provide: 'ENVIRONMENT', useValue: environment},
		{provide: DateAdapter, useClass: ApplicationDateAdapterService},
		{provide: APP_BASE_HREF, useValue: '/'},
		{provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
		{
			provide: APP_INITIALIZER,
			useFactory: InitTranslateFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
		RouterModule,
		ServiceHttpModule,
		WidgetService,
		DatePipe,

		/** Instead of HttpEndpointsModule, import only what is mandatory */
		{
			provide: AppointmentBasePath,
			deps: [EnvironmentService],
			useFactory: (e: EnvironmentService) => e.getEnvironment().endpoints['api'] + '/online-appointments'
		},
		AppointmentApiModule,

		{
			provide: PersonBasePath,
			deps: [EnvironmentService],
			useFactory: (e: EnvironmentService) => e.getEnvironment().endpoints['api'] + '/care-providers'
		},
		CareProviderApiModule,
	],
	bootstrap: [AppComponent],
	schemas: []
})
export class AppModule {
}
