import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Environment } from '@gp-angular/shared/environment';
import { EnvironmentUtils } from '@gp-angular/shared/utils';


const xmlhttp = new XMLHttpRequest();

let appConfig: Environment;

xmlhttp.onreadystatechange = () => {
	if (xmlhttp.readyState === XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
		if (xmlhttp.status === 200) {
			try {
				appConfig = JSON.parse(xmlhttp.responseText);
				environment.production = appConfig.production;
				environment.version = appConfig.version;
				environment.env = appConfig.env;
				environment.endpoints = appConfig.endpoints;
				EnvironmentUtils.init(appConfig);
			} catch(err) {
				console.log(`${err.message} in ${xmlhttp.responseText}`);
				return;
			}

			if (appConfig && appConfig.production || environment.production) {
				enableProdMode();
			}

			platformBrowserDynamic().bootstrapModule(AppModule)
				.catch(err => console.error(err));
		}
	}
};

xmlhttp.open('GET', EnvironmentUtils.getWidgetEnvironmentDomain() + '/assets/config/config.json', true);
xmlhttp.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
xmlhttp.send();
