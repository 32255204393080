import {
	AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { AppointmentTokenSchema, MediaQueryLimit, PAGE, WidgetSchema } from '@gp-angular/shared/schema';
import {
	DateUtils, FormValidators, getCurrentDate, getResetDate, pattern, ResourceUtils, TreatmentUtils, UrlEmbedData, dateDifferenceInDays
} from '@gp-angular/shared/utils';
import { WidgetService } from '@gp-angular/service/widget';
import { PublicResourceDTO, PublicTreatmentDTO, ResourceTypeEnumDTO } from '@noventi/gp-platform/care-providers';
import { FreeSlotsRequestDTO, InsuranceTypeEnumDTO } from '@noventi/gp-platform/online-appointments';

@Component({
	selector: 'noventi-widget-interface',
	templateUrl: './interface.component.html',
	styleUrls: ['./interface.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.Emulated
	//encapsulation: ViewEncapsulation.ShadowDom
})
export class InterfaceComponent extends AbstractComponent implements OnInit, AfterViewChecked {

	@ViewChild('picker', {read: undefined, static: false}) picker: MatDatepicker<Date>;

	public readonly insuranceTypes: InsuranceTypeEnumDTO[] = Object.values(InsuranceTypeEnumDTO);

	public readonly ResourceUtils = ResourceUtils;

	public readonly TreatmentUtils = TreatmentUtils;

	public readonly today = DateUtils.getDateSafe(new Date());

	public readonly intervalRangeMax = DateUtils.alterDate(DateUtils.addMonths(DateUtils.getDateSafe(new Date()), 3), -1);

	private _profile: string;

	private _iframe: boolean;

	public step = 0;

	public contact: string;

	public treatmentList$: Observable<Array<PublicTreatmentDTO>>;

	public widget: WidgetSchema = {};

	public initializing = true;

	public loading: boolean;

	public error: boolean;

	public disablePrev = true;

	public showAll: boolean;

	public showAllVisibility: boolean;

	public daysToDisplay: number;

	public daysOfWeek = [];

	public slotList = [];

	public selectedSlot: string;

	public firstDayAvailable: Date;

	public hasSlotsAvailable: boolean;

	public resourceList: Array<PublicResourceDTO> = [];

	public hasResourceList: boolean;

	public hasTreatmentList: boolean;

	public treatmentDuration: number;

	public waitingList = false;

	public rangeWaitingDates = new FormGroup(
		{
			start: new FormControl(undefined, [Validators.required]),
			end: new FormControl(undefined, [Validators.required])
		}
	);

	public rangeWaitingTimes = this.fb.group(
		{
			from: [undefined, [FormValidators.noWhitespace, Validators.pattern(pattern('time'))]],
			to: [undefined, [FormValidators.noWhitespace, Validators.pattern(pattern('time'))]]
		}, {validators: FormValidators.timeGroupSchedule()}
	);

	@ViewChild('div') div: ElementRef;

	@HostListener('window:resize', ['$event']) onResize() {
		if (!!this.div) {
			this._resize(this.div.nativeElement.offsetWidth);
		}
	}

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		private router: Router,
		private datePipe: DatePipe,
		private fb: FormBuilder,
		private widgetService: WidgetService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();
	}

	public ngOnInit(): void {
		/** Route param if no attr on widget */
		super.addSubscription(this.router.events.pipe(
			filter(event => event instanceof NavigationEnd && !this._profile)
		).subscribe(() => {
			const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
			const url = this.router.getCurrentNavigation().finalUrl.root.children.primary;
			const id = url && url.segments && url.segments.length > 0 ? url.segments[url.segments.length - 1].path : undefined;
			if (id && isUUID.test(id)) {
				this.widgetService.set(id);
			} else {
				this._error();
			}
			this._iframe = true;
		}));

		super.addSubscription(this.widgetService.get$()
			.pipe(
				tap((profile) => this._profile = profile),
				filter((profile) => !!profile),
				switchMap(() => this.widgetService.provider$(this._profile).pipe(take(1)))
			)
			.subscribe(
				(provider) => {
					this.initializing = false;
					this._setAppointmentToken({token: this._profile});
					this.contact = provider.telephone;
					this.waitingList = provider['publicSettings'].allowWaitingList;
					this.treatmentList$ = this.widgetService.treatments$(this._profile).pipe(
						tap((treatments) => {
							this.hasTreatmentList = treatments && treatments.length > 0;
							this.hasResourceList = this.hasTreatmentList && treatments.filter((treatment) =>
												   treatment.resources && treatment.resources.length > 0).length > 0;

							if (treatments && treatments.length === 1) {
								this.setTreatment(treatments[0]);
							}
							this.changeDetectorRef.markForCheck();
						})
					);
					this.moveToDate(getCurrentDate());
					this.changeDetectorRef.markForCheck();
				},
				() => this._error()
			));

		super.addSubscription(this.widgetService.getSlots$()
			.subscribe(
				(next) => {
					this.slotList = next.days;
					this.showAllVisibility = next.days.filter((day) => day.slots.length > 4).length > 0 && !next.firstDayAvailable;
					this.firstDayAvailable = next.firstDayAvailable ? getResetDate(new Date(next.firstDayAvailable)) : undefined;
					this.hasSlotsAvailable = next.available;
					this._loading();
				},
				() => {
					this._profile = undefined;
					this._loading();
				}
			));

		super.addSubscription(this.rangeWaitingDates.valueChanges
			.pipe(
				distinctUntilChanged(),
				filter((value) => !value.start || (!!value.start && !!value.end))
			)
			.subscribe((value) => {
				const START = !!value.start ? value.start : this.daysOfWeek[0];
				const INTERVAL = value.start ? 1 + dateDifferenceInDays(
					DateUtils.getDateSafe(value.end),
					DateUtils.getDateSafe(value.start)
				) : undefined;
				this._resetBookingSlot(DateUtils.getDateSafe(START), INTERVAL);
			}));

		super.addSubscription(this.rangeWaitingTimes.valueChanges
			.pipe(
				distinctUntilChanged(),
				filter(() => this.rangeWaitingDates.valid && this.rangeWaitingDates.dirty &&
							 this.rangeWaitingTimes.valid && this.rangeWaitingTimes.dirty),
				debounceTime(400)
			)
			.subscribe(() =>
				this._resetBookingSlot(DateUtils.getDateSafe(this.rangeWaitingDates.value.start),
					1 + dateDifferenceInDays(
						  DateUtils.getDateSafe(this.rangeWaitingDates.value.end),
						  DateUtils.getDateSafe(this.rangeWaitingDates.value.start))
				)
			));
	}

	public ngAfterViewChecked(): void {
		if (!!this.div) {
			this._resize(this.div.nativeElement.offsetWidth);
		}
	}

	public moveToDate(date: Date) {
		this._resetBookingSlot(date);
	}

	public moveToNext() {
		const nextWeek = new Date(this.daysOfWeek[(this.daysToDisplay - 1)]);
		nextWeek.setDate(nextWeek.getDate() + 1);
		this._resetBookingSlot(nextWeek);
	}

	public moveToPrev() {
		const prevWeek = new Date(this.daysOfWeek[0]);
		prevWeek.setDate(prevWeek.getDate() - this.daysToDisplay);
		this._resetBookingSlot(prevWeek);
	}

	public setInsurance(type: InsuranceTypeEnumDTO) {
		this._setAppointmentToken({
			insurance: type, treatment: undefined, resource: undefined, start: undefined, end: undefined
		});
		this.moveToDate(this.daysOfWeek[0]);
	}

	public setTreatment(treatment: PublicTreatmentDTO) {
		this.treatmentDuration = treatment.duration;

		const RESOURCE = treatment.resources ?
			treatment.resources.filter((resource) => resource.type === ResourceTypeEnumDTO.EMPLOYEE) : [];
		if (RESOURCE.length > 0) {
			RESOURCE.slice().sort((a, b) =>
				ResourceUtils.displayPublicResourceName(a, true).toLowerCase().localeCompare(ResourceUtils.displayPublicResourceName(b, true).toLowerCase()))
		}
		this.resourceList = RESOURCE;
		this._setAppointmentToken({
			treatment: {id: treatment.id},
			resource: undefined, start: undefined, end: undefined
		});
		this.moveToDate(this.daysOfWeek[0]);
	}

	public setResource(resource: PublicResourceDTO) {
		this._setAppointmentToken({
			resource: resource ? {id: resource.id, name: ResourceUtils.displayPublicResourceName(resource, true)} : undefined,
			start: undefined, end: undefined
		});
		this.moveToDate(this.daysOfWeek[0]);
	}

	public slotBooking(date: Date, interval: {[key: string]: string}, selectedSlot: string) {
		const START = interval.startTime.split(':');
		const END = interval.endTime.split(':');
		this._setAppointmentToken({
			start: new Date(date.setHours(+START[0], +START[1], 0, 0)),
			end: new Date(date.setHours(+END[0], +END[1], 0, 0))
		});
		this.selectedSlot = selectedSlot;
	}

	public openBooking() {
		if (!!this._iframe) {
			window.open(`${this._getUrlData()}`, '_blank');
		} else {
			window.location.href = `${this._getUrlData()}`;
		}
	}

	public openRequest() {
		const REQUEST = this._getUrlData({
			req: 1,
			ts: this.rangeWaitingTimes.value.from,
			te: this.rangeWaitingTimes.value.to
		});
		if (!!this._iframe) {
			window.open(`${REQUEST}`, '_blank');
		} else {
			window.location.href = `${REQUEST}`;
		}
	}

	public stepSlot() {
		this.step = 0;
		this.moveToDate(this.today);
		this.rangeWaitingDates.reset();
		this.rangeWaitingTimes.reset();
		this._setAppointmentToken({start: undefined, end: undefined});
	}

	public getSlotId(dayIndex, slotIndex): string {
		return `${dayIndex}_${slotIndex}`;
	}

	public getRequestDateRange(): string {
		if (this.rangeWaitingDates.value.start && this.rangeWaitingDates.value.end) {
			const START = this.datePipe.transform(this.rangeWaitingDates.value.start, 'dd.MM.YYYY', 'de-DE', 'de-DE');
			const END = this.datePipe.transform(this.rangeWaitingDates.value.end, 'dd.MM.YYYY', 'de-DE', 'de-DE');
			return START !== END ? START + ' - ' + END : START;
		}
		return '&nbsp;';
	}

	public requestDateChange(type) {
		const THIS_WEEK = DateUtils.getInterval('WEEK', {startToday: true});
		const NEXT_WEEK = DateUtils.getInterval('NEXT_WEEK');
		const THIS_MONTH = DateUtils.getInterval('THIS_MONTH', {startToday: true});
		const NEXT_MONTH = DateUtils.getInterval('NEXT_MONTH');
		switch (type) {
			case 1:
				this.rangeWaitingDates.patchValue({start: new Date(THIS_WEEK.start), end: new Date(THIS_WEEK.end)});
				break;
			case 2:
				this.rangeWaitingDates.patchValue({start: new Date(NEXT_WEEK.start), end: new Date(NEXT_WEEK.end)});
				break;
			case 3:
				this.rangeWaitingDates.patchValue({
					start: new Date(NEXT_WEEK.start), end: new Date(DateUtils.getInterval('NEXT_WEEK', {weekEnd: 2}).end)
				});
				break;
			case 4:
				this.rangeWaitingDates.patchValue({start: new Date(THIS_MONTH.start), end: new Date(THIS_MONTH.end)});
				break;
			case 5:
				this.rangeWaitingDates.patchValue({start: new Date(NEXT_MONTH.start), end: new Date(NEXT_MONTH.end)});
				break;
			default:
				this.rangeWaitingDates.reset({start: undefined, end: undefined});
				break;
		}
		this.rangeWaitingDates.markAsDirty();
		this.picker.close();
		this.changeDetectorRef.markForCheck();
	}

	public requestTimeUpdate(control) {
		if (!!control.value) {
			control.setValue(DateUtils.getTime(control.value));
		}
	}

	private _resize(width: number): void {
		const daysToDisplay = width > MediaQueryLimit.SMALL ? 7 : 4;

		if (!this.daysToDisplay || this.daysToDisplay !== daysToDisplay) {
			this.daysToDisplay = daysToDisplay;
			this.moveToDate(getCurrentDate());
		}
	}

	private _resetBookingSlot(date: Date, interval?: number) {
		if (!this._profile) {
			return;
		}
		this.daysOfWeek = this._getWeek(date);
		this.selectedSlot = undefined;

		const freeSlotsRequest: FreeSlotsRequestDTO = {
			interval: !!interval ? interval : this.daysToDisplay ? this.daysToDisplay : 7,
			startDate: date.toISOString(),
			careProviderWidgetToken: this.widget.token,
			insuranceType: this.widget.insurance
		};

		if (!!this.widget.treatment) {
			freeSlotsRequest['treatmentId'] = this.widget.treatment.id;
			freeSlotsRequest['treatmentDuration'] = this.treatmentDuration;
		}
		if (!!this.widget.resource) {
			freeSlotsRequest['resourceIds'] = [this.widget.resource.id];
		}

		if (!!this.widget.resource) {
			freeSlotsRequest['resourceIds'] = [this.widget.resource.id];
		}

		if (this.rangeWaitingTimes && this.rangeWaitingTimes.valid && this.rangeWaitingTimes.dirty) {
			freeSlotsRequest['startTime'] = this.rangeWaitingTimes.value.from;
			freeSlotsRequest['endTime'] = this.rangeWaitingTimes.value.to
		}

		this._loading(true);
		this.widgetService.loadSlots(freeSlotsRequest, this.step === 0);
	}

	private _setAppointmentToken(x: { [key: string]: string | any }) {
		Object.keys(x).forEach((key) => this.widget[key] = x[key]);
		this.selectedSlot = undefined;
	}

	private _getUrlData(request?: any): string {
		const data = {};
		if (!!this.widget) {
			/** Request only */
			if (request) {
				Object.keys(request).forEach((key) => {
					if (!!request[key]) {
						data[key] = request[key];
					}
				});

				data['startTime'] = this.rangeWaitingDates.value.start;
				data['endTime'] = this.rangeWaitingDates.value.end;

				if (!!data['ts']) {
					data['ts'] = this.rangeWaitingTimes.value.from;
				}
				if (!!data['te']) {
					data['te'] = this.rangeWaitingTimes.value.to;
				}
			}
			if (this.widget.token) {
				data['widgetToken'] = this.widget.token;
			}
			if (this.widget.insurance) {
				data['insuranceType'] = this.widget.insurance;
			}
			if (this.widget.treatment) {
				data['selectedTreatment'] = this.widget.treatment;
			}
			if (this.widget.resource) {
				data['selectedResource'] = this.widget.resource;
			}
			if (this.widget.start) {
				data['startTime'] = this.widget.start;
			}
			if (this.widget.end) {
				data['endTime'] = this.widget.end;
			}
		}
		return `${this._environment.endpoints.booking}/${PAGE.APPOINTMENT_REQUEST_PROCESS.path}?id=${UrlEmbedData.encodeAppointmentToken(data as AppointmentTokenSchema)}`
	}

	private _getWeek(today: Date) {
		// For start of the week use the code below:
		// Don't ask why we have it, only me and Atti know
		// After a week nobody will even remember it!

		const DATE: Date = getResetDate(today);
		const DAYS_OF_WEEK = [DATE];
		for (let i = 1; i < this.daysToDisplay; i++) {
			const currentDay = new Date(DATE);
			currentDay.setDate(currentDay.getDate() + i);
			DAYS_OF_WEEK.push(currentDay);
		}

		this.disablePrev = new Date(DAYS_OF_WEEK[0].getFullYear(), DAYS_OF_WEEK[0].getMonth(),
			DAYS_OF_WEEK[0].getDate()).getTime() < new Date().getTime();

		return DAYS_OF_WEEK;
	}

	private _error(): void {
		this.error = true;
		this.changeDetectorRef.markForCheck();
	}

	private _loading(value: boolean = false): void {
		this.loading = value;
		this.changeDetectorRef.markForCheck();
	}
}
